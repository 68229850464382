<template>
  <div class="shares-wrapper">
    <span class="icon" @click="toggleBoxShown()"></span>
    <div class="box-wrapper" v-if="box_shown">
      <h4>Condividi CEP con:</h4>
      <ul>
        <li v-for="user in users" :class="shared_user_ids.filter((item) => { return item.user_id==user.id; }).length>0 ? 'active' : ''" @click="toggleShare(user.id)">{{$niceUserFullName(user)}}</li>
      </ul>
      <span class="save-btn" @click="saveShares()">Salva</span>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      current_user: Object,
      cep_id: Number,
      cep_shares: Array,
      users: Array,
    },

    data(){
      return {
        box_shown: false,

        shared_user_ids: this.cep_shares.map((item) => {
          return {
            id: item.id,
            user_id: item.user_id,
            cep_id: item.cep_id,
            owner_id: item.owner_id,
          };
        }),
      }
    },

    methods: {
      ...helperFunctions,

      toggleBoxShown(){
        this.box_shown = !this.box_shown;
      },

      toggleShare(user_id){
        if(this.shared_user_ids.filter((item) => { return item.user_id==user_id; }).length>0){
          var index = this.shared_user_ids.map((item) => { return item.user_id; }).indexOf(user_id);
          if(index>=0){
            this.shared_user_ids.splice(index, 1);
          }
        }
        else{
          this.shared_user_ids.push({
            id: null,
            user_id: user_id,
            cep_id: this.cep_id,
            owner_id: this.current_user.id,
          });
        }
      },

      saveShares(){
        var params = {
          cep: {
            // Comp section
              comp_section: 's',
            // Shares
              shares: this.shared_user_ids,
          }
        };
        this.axios.put('/api/ceps/'+this.cep_id+'/share', params).then(response => {
          var obj = response.data;
          if(obj.data.success){
            this.toggleBoxShown();
            // window.location.href = '/ceps/'+obj.data.cep_id;
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>