<template>
  <div>
    <h1>
      Bentornato, <br>ecco i tuoi codici progetto
      <div class="filters-wrapper">
        <CInputSearch id="search_term" name="search_term" placeholder="..." :readonly="false" v-model.number="search_term"></CInputSearch>
      </div>
    </h1>
    <template v-if="ceps.length>0">
      <table class="table-wrapper">
        <thead>
          <tr>
            <th :class="order.key=='project_code' ? order.dir : ''">
              <span @click="toggleOrder('project_code')">Cod. progetto <span class="order"></span></span>
            </th>
            <th :class="order.key=='client_name' ? order.dir : ''">
              <span @click="toggleOrder('client_name')">Cliente <span class="order"></span></span>
            </th>
            <th :class="order.key=='created_at' ? order.dir : ''">
              <span @click="toggleOrder('created_at')">Data creazione <span class="order"></span></span>
            </th>
            <th :class="order.key=='updated_at' ? order.dir : ''">
              <span @click="toggleOrder('updated_at')">Ultima modifica <span class="order"></span></span>
            </th>
            <th>
              <span>Link cartella Drive</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-row table-content noc" v-for="cep in ceps">
            <td class="table-cell p-code">{{$niceField(cep.project_code)}}</td>
            <td class="table-cell">{{$niceField(cep.client_name)}}</td>
            <td class="table-cell date">{{$niceDate(cep.created_at)}}</td>
            <td class="table-cell date">{{$niceDate(cep.updated_at)}}</td>
            <td class="table-cell">
              <template v-if="cep.drive_url">
                <a :href="cep.drive_url" title="Link cartella Drive" class="drive-folder"></a>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="page_number>1">
        <span class="prev" @click="prevPage"></span>
        <span v-for="page in page_range" class="item" :class="page==current_page ? 'active' : ''" @click="selectPage(page)">{{page}}</span>
        <span class="next" @click="nextPage"></span>
        <span class="total-page">di {{page_number}}</span>
      </div>
    </template>
    <template v-else>
      <p class="no-rows" v-if="ceps.length==0">La ricerca non ha prodotto alcun risultato.</p>
    </template>
  </div>
</template>

<script>
  import CInputSearch from "../form/CInputSearch.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CInputSearch,
    },

    data(){
      return {
        search_term: '',
        awaiting_search: false,

        order: {
          key: null,
          dir: null,
        },

        ceps: [],

        limit: 50,
        offset: 0,
        current_page: 1,
        page_number: 0,
        page_range: [],
        page_shown: 7,
      }
    },

    created(){
      this.getInitValues();
    },

    watch: {
      search_term: function(val){
        if(!this.awaiting_search){
          setTimeout(() => {
            this.getInitValues('filters');
            this.awaiting_search = false;
          }, 750);
        }
        this.awaiting_search = true;
      },
    },

    methods: {
      ...helperFunctions,

      toggleOrder(key){
        if(this.order.key!=key){
          this.order.key = key;
          this.order.dir = 'asc';
        }
        else{
          if(this.order.dir=='asc'){
            this.order.dir = 'desc';
          }
          else{
            this.order.key = null;
            this.order.dir = null;
          }
        }
        this.getInitValues();
      },

      prevPage(){
        if(this.current_page>1){
          this.current_page--;
          this.offset--;
          this.getInitValues();
        }
      },

      selectPage(page_index){
        if(this.current_page!=page_index){
          this.current_page=page_index;
          this.offset=page_index-1;
          this.getInitValues();
        }
      },

      nextPage(){
        if(this.current_page<this.page_number){
          this.current_page++;
          this.offset++;
          this.getInitValues();
        }
      },

      calculatePageRange(){
        var temp_page_range = [];
        var start = 0;
        var end = this.page_shown;
        if(this.page_number>this.page_shown){
          if(this.current_page<4){
            start = 0;
            end = this.page_shown;
          }
          else if(this.current_page>(this.page_number-4)){
            start = this.page_number-this.page_shown;
            end = this.page_number;
          }
          else{
            start = this.current_page-Math.ceil(this.page_shown/2);
            end = this.current_page+Math.floor(this.page_shown/2);
          }
        }
        else{
          end = this.page_number;
        }
        for(var i=start;i<end;i++){
          temp_page_range.push(parseInt(i+1));
        }
        this.page_range = temp_page_range;
      },

      calculateOffset(){
        return this.limit*this.offset;
      },

      getInitValues(type=null){
        if(type=='filters'){
          this.offset = 0;
        }
        var params = {params: {limit: this.limit, offset: this.calculateOffset(), search_term: this.search_term, order: this.order}};
        this.axios.get('/api/hcc', params).then(response => {
          var obj = response.data;
          if(obj.data.success){
            this.ceps = obj.data.ceps;

            if(type=='filters'){
              this.current_page = 1;
            }

            this.page_number = Math.ceil(obj.data.ceps_count/this.limit);
            this.calculatePageRange();
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>