// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import axios from 'axios';
import { createApp } from 'vue/dist/vue.esm-bundler.js';

// Shared
  import ToggleTheme from '../components/header/ToggleTheme.vue';

// Ceps
  import Ceps from '../components/ceps/Ceps.vue';
  import HccCeps from '../components/ceps/HccCeps.vue';
  import CepShares from '../components/ceps/CepShares.vue';
  import NewCepFormWrapper from '../components/ceps/NewCepFormWrapper.vue';
  import NewCepForm from '../components/ceps/NewCepForm.vue';
  import NewHccCepFormWrapper from '../components/ceps/NewHccCepFormWrapper.vue';
  import NewHccCepForm from '../components/ceps/NewHccCepForm.vue';
  import CepFormWrapper from '../components/ceps/CepFormWrapper.vue';
  import CepForm from '../components/ceps/CepForm.vue';
  import CepFormGeneralInfo from '../components/ceps/CepFormGeneralInfo.vue';
  import CepFormCE from '../components/ceps/CepFormCE.vue';
  import CepFormP from '../components/ceps/CepFormP.vue';
  import CepFormL from '../components/ceps/CepFormL.vue';
  import CepFormH from '../components/ceps/CepFormH.vue';
  import CepWrapper from '../components/ceps/CepWrapper.vue';
  import Cep from '../components/ceps/Cep.vue';
  import CepCE from '../components/ceps/CepCE.vue';
  import CepP from '../components/ceps/CepP.vue';
  import CepL from '../components/ceps/CepL.vue';
  import CepAlerts from '../components/ceps/CepAlerts.vue';
  import CepDelete from '../components/ceps/CepDelete.vue';
  import CepEditProjectCode from '../components/ceps/CepEditProjectCode.vue';

// Users
  import Users from '../components/users/Users.vue';

// Clients
  import Clients from '../components/clients/Clients.vue';

// Markets
  import Markets from '../components/markets/Markets.vue';

// Partners
  import Partners from '../components/partners/Partners.vue';

// Sceneries
  import Sceneries from '../components/sceneries/Sceneries.vue';

// Signalmen
  import Signalmen from '../components/signalmen/Signalmen.vue';

// AppDefVals
  import AppDefVals from '../components/app_def_vals/AppDefVals.vue';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

var vue_el = document.getElementById('vue-app');
if(vue_el){
  const app = createApp();
  app.config.globalProperties.axios = axios;

  // Shared
    app.component('ToggleTheme', ToggleTheme);

  // Ceps
    app.component('Ceps', Ceps);
    app.component('HccCeps', HccCeps);
    app.component('CepShares', CepShares);
    app.component('NewCepFormWrapper', NewCepFormWrapper);
    app.component('NewCepForm', NewCepForm);
    app.component('NewHccCepFormWrapper', NewHccCepFormWrapper);
    app.component('NewHccCepForm', NewHccCepForm);
    app.component('CepFormWrapper', CepFormWrapper);
    app.component('CepForm', CepForm);
    app.component('CepFormGeneralInfo', CepFormGeneralInfo);
    app.component('CepFormCE', CepFormCE);
    app.component('CepFormP', CepFormP);
    app.component('CepFormL', CepFormL);
    app.component('CepFormH', CepFormH);
    app.component('CepWrapper', CepWrapper);
    app.component('Cep', Cep);
    app.component('CepCE', CepCE);
    app.component('CepP', CepP);
    app.component('CepL', CepL);
    app.component('CepAlerts', CepAlerts)
    app.component('CepDelete', CepDelete);
    app.component('CepEditProjectCode', CepEditProjectCode);

  // Users
    app.component('Users', Users);

  // Clients
    app.component('Clients', Clients);

  // Markets
    app.component('Markets', Markets);

  // Partners
    app.component('Partners', Partners);

  // Sceneries
    app.component('Sceneries', Sceneries);

  // Signalmen
    app.component('Signalmen', Signalmen);

  // AppDefVals
    app.component('AppDefVals', AppDefVals);

  const mountedApp = app.mount("#vue-app");
}

// Menu handler
  var el = document.getElementById('toggle-menu');
  if(el){
    el.addEventListener('click', function handleClick(event){
      el.classList.toggle('shown');
    });
  }
